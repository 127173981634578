.footer {
    background-color: var(--primary-blue);
    width: 100%;

    & .contact-form {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-around;
        background-color: var(--primary-blue);
        padding: 50px 0px;
        width: 100%;
        
        & .info {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-between;
            gap: 20px;
            min-width: 320px;
            & hr {
                display: none;
                margin: 0px;
                border-color: var(--primary-gold);
                filter: opacity(0.4);
            }

            & h2 {
                color: var(--primary-gold);
            }

            & label {
                color: var(--primary-white-text);
            }

            & #buttons {
                display: flex;
                flex-direction: column;
                gap: 20px;

                & .button {
                    border: 3px solid var(--primary-gold);
                    background-color: var(--primary-blue);
                    color: var(--primary-gold);
                    width: 200px;
                    height: 50px;
                    font-weight: 700;
                    cursor: pointer;

                    &:active {
                        filter: opacity(0.8);
                    }
                }

                & .wap {
                    background-color: #AFD5A0;
                    width: 200px;
                    height: 50px;
                    font-weight: 700;
                    cursor: pointer;

                    &:active {
                        filter: opacity(0.8);
                    }
                }
            }

            & .socials {
                & .social {
                    height: 50px;
                    width: 50px;
                    cursor: pointer;
                }

                & .social:active {
                    filter: opacity(0.8);
                }
            }
        }

        & .form {
            background-color: var(--primary-white);
            display: flex;
            flex-direction: column;
            gap: 20px;
            padding: 20px;
            max-width: 600px;
            min-width: 320px;
            box-sizing: border-box;
            border-radius: 5px;
            & input {
                &.text {
                    height: 36px;
                }

                & #submit{
                    height: 130px;
                }
            }

            input[type=submit]{
                height: 100px;
            }

            & textarea {
                height: 80px;
            }

            #buttons{
                display: flex;
                justify-content: center;
                align-items: center;
                #button{
                    background-color: var(--primary-gold);
                    width: 200px;
                }
            }
        }
    }

    & label {
        font-size: 16px;
    }

    & .copyright {
        margin: 0px;
        padding: 0px 0px 30px 0px;
        text-align: center;
        color: var(--primary-white-text);
        background-color: var(--primary-blue);
        font-size: 16px;
    }
}


@media (max-width:760px) {
    .footer {
        & .contact-form {
            gap: 50px;
            text-align: center;

            & .info {
                align-items: center;
                & hr {
                    display: flex;
                    width: 100%;
                }

                & h2 {
                    width: 100%;
                }

                & #buttons{
                    width: 100%;
                    align-items: center;
                }

                & .socials{
                    display: flex;
                    width: 100%;
                    justify-content: space-evenly;
                }
            }
        }
    }
}